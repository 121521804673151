import * as React from "react"
import {graphql} from 'gatsby';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {StaticImage} from "gatsby-plugin-image"
import Seo from '../components/Seo';
import Layout from '../components/layout/Layout';

// markup
const ThankYouPage = () => {
  const {t} = useTranslation();
  return (
    <Layout>
      <Seo />
      <section className="text-blue-800 body-font">
        <div className="my-cont flex md:flex-row flex-col items-center">
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-2 md:mb-0">
            <StaticImage className="object-cover object-center rounded" src="../images/thankyou.jpg" alt="Thank you!" />
          </div>
          <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
            <h1 className="title-font sm:text-4xl text-3xl font-medium">{t('msg.thankyou')}</h1>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ThankYouPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}, ns: {in: ["translation", "thankyou"]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
